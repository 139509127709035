import { template as template_ec7eb0b06cf747098326c893e196dbbf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ec7eb0b06cf747098326c893e196dbbf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
