import { template as template_bdc2ecc0206a4c9eb1e144344844d8a4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bdc2ecc0206a4c9eb1e144344844d8a4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
