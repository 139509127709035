import { template as template_e96eaa062387414a97adbba6e4c28778 } from "@ember/template-compiler";
const WelcomeHeader = template_e96eaa062387414a97adbba6e4c28778(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
