import { template as template_0b753de14e29441792de8400a0d52424 } from "@ember/template-compiler";
const FKLabel = template_0b753de14e29441792de8400a0d52424(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
