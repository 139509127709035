import { template as template_1ba98011a0544580b58d11c1c038c8d2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1ba98011a0544580b58d11c1c038c8d2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
