import { template as template_86a4527366f14e0e9b579534ccb3d671 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_86a4527366f14e0e9b579534ccb3d671(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
